<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column">
            <h2 class="title is-3">Добавить форму сбора данных</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <v-select
              :items="forms"
              item-text="name"
              item-value="id"
              label="Форма"
              v-model="currentForm.id_form"
            ></v-select>

            <div class="level-item column is-1">
              <div class="image" style="width: 250px;">
                <img :src="selectedFormPreview" />
              </div>
            </div>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" @click="addForm()">Добавить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddDataFormModal',
  components: {
  },
  data() {
    return {
    };
  },
  props: [
    'application_id',
  ],
  created() {
    this.$store.dispatch("GET_FORMS", { page: 'all' });
  },
  computed: {
    selectedFormPreview() {
      if (!this.currentForm.id_form) return ''; // Если форма не выбрана, возвращаем пустую строку
      const selectedForm = this.forms.find(form => form.id === this.currentForm.id_form);
      return selectedForm ? selectedForm.preview_path : '';
    },
    forms() {
      return this.$store.getters.GET_FORMS ? this.$store.getters.GET_FORMS.results : [];
    },
    isOpen: {
      get() {
        return this.$store.getters.ADD_FORM_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit("SET_ADD_FORM_MODAL_OPENED", value);
      },
    },
    currentForm: {
      get() {
        return this.$store.getters.GET_CURRENT_FORM;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_FORM", value);
      },
    },
  },
  methods: {
    close() {
      this.currentForm = {};
      this.isOpen = false;
    },
    addForm() {
      this.currentForm.application_id = this.application_id;
      this.currentForm.typeOfOffer = 'data_collection_form';

      this.$store.dispatch('CREATE_OFFER', this.currentForm)
        .then(() => {
          this.$toast.success("Форма успешно добавлена");
          this.currentForm = {};
          this.isOpen = false;
          this.$store.dispatch("GET_APPLICATION_BY_ID", this.application_id);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении формы");
        });
    },
  },
};
</script>
<style>
</style>
